import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";
import LogoBlack from '../Images/LogoBlack.png';
import "../Style/Footer.css";

const Footer = () => {
  const handleMenuClick = (event, section) => {
    event.preventDefault(); // Отменяем стандартное поведение ссылки
    const target = document.getElementById(section); // Получаем элемент по ID
    if (target) {
      const headerOffset = 140; // Высота фиксированного заголовка, если он есть
      const targetOffset = target.offsetTop - headerOffset;
      window.scrollTo({
        top: targetOffset,
        behavior: "smooth"
      });
    }
  };

  return (
    <footer className="footer">
      <Container>
        <Row className="footer-row">
          <Col xs={12} md={4} className="footer-col">
            <a href="#top" className="logo-container social-icons">
              <h5><img src={LogoBlack} alt="Logo" className="logo-black-footer"></img>- твій центр підготовки до НМТ</h5>
            </a>
            <ul className="social-icons">
              <li className="menu-item-footer">
                <a
                  href="#Компанія"
                  onClick={(event) => handleMenuClick(event, 'Компанія')}
                >
                  Компанія
                </a>
              </li>
              <li className="menu-item-footer">
                <a
                  href="#Предмети"
                  onClick={(event) => handleMenuClick(event, 'Предмети')}
                >
                  Предмети
                </a>
              </li>
              <li className="menu-item-footer">
                <a
                  href="#Події"
                  onClick={(event) => handleMenuClick(event, 'Події')}
                >
                  Події
                </a>
              </li>
              <li className="menu-item-footer">
                <a
                  href="#Формат підготовки"
                  onClick={(event) => handleMenuClick(event, 'Формат підготовки')}
                >
                  Формат підготовки
                </a>
              </li>
              <li className="menu-item-footer">
                <a
                  href="https://drive.google.com/file/d/1hfVqt-5jUpYfXhXdews1ZpUYlmCoYYsf/view?usp=sharing" target="_blank" 
                  
                >
                  Публічна оферта
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={4} className="footer-col">
            <h5>Наші соцмережі</h5>
            <ul className="social-icons">
              <li>
                <a
                  href="https://www.facebook.com/unext.ua?locale=uk_UA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook /> Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/unext.ua?igsh=eXMxbGU5enB5bnRz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram /> Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@unext_ua?_t=8nMKqKHZmeT&_r=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTiktok/> TikTok
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={4} className="footer-col">
            <h5>Контакти</h5>
            <p>
              Телефон: <a href="tel:+380731776422">+38 (073) 177 64 22</a>
            </p>
            <p>
              Email: <a href="mailto:unext2024unext@gmail.com">unext2024unext@gmail.com</a>
            </p>
          </Col>
        </Row>
      </Container>
      <div className="footer-bottom">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <p>
                &copy; {new Date().getFullYear()} Unext. Всі права захищені.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
