import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import UnextMap from "../Images/UnextMap2.png";
import "../Style/Format.css"; // Подключаем файл стилей

const Format = ({ imageSrc }) => {
  return (
    <Container fluid className="my-3 format-container">
      <Row className="mt-4 ">
        <Col xs={12} md={3} className="city-card-container">
          <div className="city-card">
            <h3 className="text-uppercase">КИЇВ</h3>
            <p>
              <a
                href="https://maps.app.goo.gl/to9awSat18PkfTSa9"
                target="_blank"
                className="link-address"
                rel="noreferrer" 
              >
                вул. Анни Ахматової, 22, 2-й поверх, TechKids Academy
              </a>
            </p>
          </div>
        </Col>
        <Col xs={12} md={3} className="city-card-container">
          <div className="city-card">
            <h3 className="text-uppercase">БРОВАРИ</h3>
            <p>
              <a
                href="https://maps.app.goo.gl/aPKn2dRTNCW3eJkz7"
                target="_blank"
                className="link-address"
                rel="noreferrer" 
              >
                вул. Київська, 245, Soroban
              </a>
            </p>
          </div>
        </Col>
        
        <Col xs={12} md={3} className="city-card-container">
          <div className="city-card">
            <h3 className="text-uppercase">ЧЕРНІВЦІ</h3>
            <p>
              <a
                href="https://maps.app.goo.gl/1iiieiYQ4mR2WQoE9"
                target="_blank"
                className="link-address"
                rel="noreferrer" 
              >
                просп. Незалежності, 111, 2 поверх, Soroban
              </a>
            </p>
          </div>
        </Col>
        <Col xs={12} md={3} className="city-card-container">
          <div className="city-card">
            <h3 className="text-uppercase">РІВНЕ</h3>
            <p>
              <a
                href="https://maps.app.goo.gl/gALkGoFFN9GQLU156"
                target="_blank"
                className="link-address"
                rel="noreferrer" 
              >
                вул. Соборна, 192д, 4 поверх, TechKids Academy
              </a>
            </p>
          </div>
        </Col>
        
      </Row>

      <Row className="cities-container">
        <Col xs={12} md={4} className="city-card-container">
          <div className="city-card">
            <h3 className="text-uppercase">ЖИТОМИР</h3>
            <p>
              <a
                href="https://maps.app.goo.gl/xbX2Z8MhXzbRpCaj6"
                target="_blank"
                className="link-address"
                rel="noreferrer" 
              >
                вул. Святослава Ріхтера, 33, Soroban
              </a>
            </p>
          </div>
        </Col>
        <Col xs={12} md={4} className="city-card-container">
          <div className="city-card">
            <h3 className="text-uppercase">ХМЕЛЬНИЦЬКИЙ</h3>
            <p>
              <a
                href="https://maps.app.goo.gl/yL5ZH34QLkkz7ohEA"
                target="_blank"
                className="link-address"
                rel="noreferrer" 
              >
                вул. Подільська, 21, ТЦ «Магніт», 2-й поверх, Soroban
              </a>
            </p>
          </div>
        </Col>
        <Col xs={12} md={4} className="city-card-container">
          <div className="city-card">
            <h3 className="text-uppercase">ІВАНО-ФРАНКІВСЬК</h3>
            <p>
              <a
                href="https://maps.app.goo.gl/zFKrXMN6YAP1yH7X7"
                target="_blank"
                className="link-address"
                rel="noreferrer" 
              >
                вул. Володимира Великого, 10а, Soroban
              </a>
            </p>
          </div>
        </Col>
      </Row>
      {/* <Row className="mt-4 d-flex justify-content-center align-items-center cities-container">
        <Col xs={12} md={4} className="city-card-container">
          <div className="city-card">
            <h3 className="text-uppercase">БРОВАРИ</h3>
            <p>вул. Київська, 245</p>
          </div>
        </Col>
      </Row> */}

      <Row>
        <Col xs={12} className="text-center mb-5">
          <img
            src={imageSrc}
            alt="Карта Украины"
            className="img-fluid map-image"
          />
        </Col>
      </Row>
    </Container>
  );
};

const ExampleComponent = () => {
  return (
    <div>
      <h1 className="text-center my-4 title-card-format mt-5 mb-5">
        Навчальні центри та представництва
      </h1>
      <Format imageSrc={UnextMap} />
    </div>
  );
};

export default ExampleComponent;
