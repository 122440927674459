import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LogoWhite from '../Images/LogoWhite.png';
import LogoBlack from '../Images/LogoBlack.png';
import '../Style/Header.css';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const headerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);

      if (location.pathname === '/') {
        const sections = document.querySelectorAll('section');
        let currentSection = '';

        sections.forEach(section => {
          const sectionTop = section.offsetTop;
          if (scrollTop >= sectionTop - 300) {
            currentSection = section.getAttribute('id');
          }
        });

        setActiveSection(currentSection);
      } else if (location.pathname === '/company') {
        setActiveSection('company');
      } else {
        setActiveSection('');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]);

  const scrollToSection = (section) => {
    const targetSection = document.getElementById(section);
    if (targetSection) {
      const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;
      const sectionTop = targetSection.offsetTop;

      window.scrollTo({
        top: sectionTop - headerHeight - 60,
        behavior: 'smooth'
      });
    }
  };

  const handleMenuClick = (event, section) => {
    event.preventDefault();
    if (location.pathname === '/') {
      scrollToSection(section);
    } else {
      navigate(`/#${section}`);
    }
  };

  useEffect(() => {
    if (location.hash) {
      const section = location.hash.substring(1);
      const targetSection = document.getElementById(section);
      if (targetSection) {
        scrollToSection(section);
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location]);

  const handleLogoClick = (event) => {
    event.preventDefault();
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate('/');
    }
  };

  return (
    <header ref={headerRef} className={`header ${isScrolled ? 'white' : 'transparent'}`}>
      <div className="container">
        <div className="navbar">
          <div className="menu-container">
            <a href="/" className="logo-container" onClick={handleLogoClick}>
              <img className="logoHeader" src={isScrolled ? LogoBlack : LogoWhite} alt="Лого фото" title="Лого" />
            </a>
            <ul className="menu-header">
              {['Компанія', 'Предмети', 'Формат підготовки'].map(section => (
                <li className="menu-item-header" key={section}>
                  {section === 'Компанія' ? (
                    <Link
                      to="/company"
                      className={`company ${location.pathname === '/company' || activeSection === 'company' ? 'active' : ''}`}
                    >
                      {section}
                    </Link>
                  ) : (
                    <a
                      href={`#${section}`}
                      className={`${location.pathname === '/' && activeSection === section ? 'active' : ''}`}
                      onClick={(event) => handleMenuClick(event, section)}
                    >
                      {section}
                    </a>
                  )}
                </li>
              ))}
              <li className="menu-item-header" key="phone">
                <a href="tel:+380731776422" className="phone">
                  +38 (073) 177 64 22
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
