import React, { useState } from "react";
import "../Style/MapPage.css";
import UnextMap from "../Images/UnextMap2.png";

const cities = [
  {
    name: "Рівне",
    info: "вул. Соборна, 192д, 4 поверх, TechKids Academy",
    link: "https://maps.app.goo.gl/gALkGoFFN9GQLU156",
    style: { top: "18%", left: "29%" },
  },
  {
    name: "Житомир",
    info: "вул. Святослава Ріхтера, 33, Soroban",
    link: "https://maps.app.goo.gl/xbX2Z8MhXzbRpCaj6",
    style: { top: "24%", left: "37%" },
  },
  {
    name: "Хмельницький",
    info: "вул. Подільська, 21, ТЦ «Магніт», 2-й поверх, Soroban",
    link: "https://maps.app.goo.gl/yL5ZH34QLkkz7ohEA",
    style: { top: "36%", left: "31%" },
  },
  {
    name: "Київ",
    info: "вул. Анни Ахматової, 22, 2-й поверх, TechKids Academy",
    link: "https://maps.app.goo.gl/to9awSat18PkfTSa9",
    style: { top: "33%", left: "45%" },
  }, 
  {
    name: "Бровари",
    info: " вул. Київська, 245, Soroban",
    link: "https://maps.app.goo.gl/aPKn2dRTNCW3eJkz7",
    style: { top: "29%", left: "51%" },
  },
  {
    name: "Івано-Франківськ",
    info: "вул. Володимира Великого, 10а, Soroban",
    link: "https://maps.app.goo.gl/zFKrXMN6YAP1yH7X7",
    style: { top: "47%", left: "19%" },
  },
  {
    name: "Чернівці",
    info: "просп. Незалежності, 111, 2 поверх, Soroban",
    link: "https://maps.app.goo.gl/1iiieiYQ4mR2WQoE9",
    style: { top: "52%", left: "25%" },
  },
];

const MapPage = () => {
  const [hoveredCity, setHoveredCity] = useState(null);
  return (
    <>
      <h1 className="text-center my-4 title-card-format mt-5 mb-5">
        Навчальні центри та представництва
      </h1>
      <div className="map-container-MapPage">
        <img src={UnextMap} alt="Map" className="map-image-MapPage" />
        {cities.map((city, index) => (
          <div
            key={index}
            className="city-marker-MapPage"
            style={city.style}
            onMouseEnter={() => setHoveredCity(city)}
            onMouseLeave={() => setHoveredCity(null)}
          >
            {hoveredCity === city && (
              <div
                className="popup-MapPage"
                style={{
                  bottom: "100%",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <h5>{city.name}</h5>
                <a href={city.link} target="_blank" className="link-MapPage-address" rel="noopener noreferrer">{city.info}</a>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default MapPage;
