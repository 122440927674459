import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/Company.css";
import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";
import Footer from "../Components/Footer";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const Company = () => {
  const [ref1, inView1] = useInView({ triggerOnce: true, rootMargin: "-50px" });
  const [ref2, inView2] = useInView({ triggerOnce: true, rootMargin: "-50px" });
  const [ref3, inView3] = useInView({ triggerOnce: true, rootMargin: "-50px" });
  const [ref4, inView4] = useInView({ triggerOnce: true, rootMargin: "-50px" });
  const [ref5, inView5] = useInView({ triggerOnce: true, rootMargin: "-50px" });
  const [ref6, inView6] = useInView({ triggerOnce: true, rootMargin: "-50px" });
  const [ref7, inView7] = useInView({ triggerOnce: true, rootMargin: "-50px" });
  const [ref8, inView8] = useInView({ triggerOnce: true, rootMargin: "-50px" });
  const [ref9, inView9] = useInView({ triggerOnce: true, rootMargin: "-50px" });
  const [ref10, inView10] = useInView({
    triggerOnce: true,
    rootMargin: "-50px",
  });
  const [ref11, inView11] = useInView({
    triggerOnce: true,
    rootMargin: "-50px",
  });
  const isMobileDevice = () => {
    // Логика для определения мобильных устройств
    const userAgent = window.navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  }
  
  const rootMarginValue = isMobileDevice() ? "900px" : "200px";
  
  const [ref12, inView12] = useInView({
    triggerOnce: true,
    rootMargin: rootMarginValue,
  });

  return (
    <>
      <section className="hero-company">
        <div className="overlay"></div>
        <Container>
          <Row className="align-items-center">
            <Col md={12} className="text-center text-md-left">
              <motion.p
                initial="hidden"
                animate={inView1 ? "visible" : "hidden"}
                variants={fadeInUp}
                transition={{ duration: 0.6 }}
                ref={ref1}
                className="CompanyPageText"
              >
                Освітній центр Unext - це команда досвідчених викладачів, які з
                2017 року допомагають учням досягати високих результатів на ЗНО
                (тепер НМТ) та ДПА. За час нашої роботи ми випустили понад 3500
                успішних абітурієнтів, які здобули освіту в найкращих
                університетах України.
              </motion.p>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="bg-container-company">
        <div className="page-container">
          <div className="container">
            <section className="timeline mb-5">
              <h2 className="text-center mb-4 section-title">Історія Unext</h2>
              <ul className="timeline-list">
                <li>
                  <Row className="timeline-item">
                    <Col xs={12} md={2} className="timeline-date-container">
                      <span className="timeline-date">2017 -</span>
                    </Col>
                    <Col xs={12} md={10} className="timeline-content">
                      <motion.div
                        initial="hidden"
                        animate={inView2 ? "visible" : "hidden"}
                        variants={fadeInUp}
                        transition={{ duration: 0.6 }}
                        ref={ref2}
                      >
                        Заснування освітнього центру. Старт та перші успішні
                        показники навчальної діяльності нашиx випускників;
                      </motion.div>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row className="timeline-item">
                    <Col xs={12} md={2} className="timeline-date-container">
                      <span className="timeline-date">2020 -</span>
                    </Col>
                    <Col xs={12} md={10} className="timeline-content">
                      <motion.div
                        initial="hidden"
                        animate={inView3 ? "visible" : "hidden"}
                        variants={fadeInUp}
                        transition={{ duration: 0.6, delay: 0.1 }}
                        ref={ref3}
                      >
                        Перезапуск компанії під новим брендом Unext. Формування
                        глобальної мети та цілі. Запуск якісно-нових продуктів:
                        Літній табір; онлайн-платформа; зйомки навчальниx
                        відео-уроків в співпраці з Освіторією для Всеукраїнської
                        онлайн школи, трансформація курсів на онлайн формат під
                        впливом пандемії коронавірусу;
                      </motion.div>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row className="timeline-item">
                    <Col xs={12} md={2} className="timeline-date-container">
                      <span className="timeline-date">2021 -</span>
                    </Col>
                    <Col xs={12} md={10} className="timeline-content">
                      <motion.div
                        initial="hidden"
                        animate={inView4 ? "visible" : "hidden"}
                        variants={fadeInUp}
                        transition={{ duration: 0.6, delay: 0.2 }}
                        ref={ref4}
                      >
                        Більше 1000 клієнтів за навчальний рік, розширення
                        мережі офісів в Києві; онлайн/офлайн заняття;
                      </motion.div>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row className="timeline-item">
                    <Col xs={12} md={2} className="timeline-date-container">
                      <span className="timeline-date">2022 -</span>
                    </Col>
                    <Col xs={12} md={10} className="timeline-content">
                      <motion.div
                        initial="hidden"
                        animate={inView5 ? "visible" : "hidden"}
                        variants={fadeInUp}
                        transition={{ duration: 0.6, delay: 0.3 }}
                        ref={ref5}
                      >
                        Початок війни, адаптація занять до воєнниx умов;
                        трансформація на благодійний напрямок компанії.
                        Безкоштовні уроки для учнів і завершення навчального
                        року;
                      </motion.div>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row className="timeline-item">
                    <Col xs={12} md={2} className="timeline-date-container">
                      <span className="timeline-date">07.2022 -</span>
                    </Col>
                    <Col xs={12} md={10} className="timeline-content">
                      <motion.div
                        initial="hidden"
                        animate={inView6 ? "visible" : "hidden"}
                        variants={fadeInUp}
                        transition={{ duration: 0.6, delay: 0.4 }}
                        ref={ref6}
                      >
                        Тимчасове призупинення діяльності компанії через
                        військові дії на території України;
                      </motion.div>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row className="timeline-item">
                    <Col xs={12} md={2} className="timeline-date-container">
                      <span className="timeline-date">2024 -</span>
                    </Col>
                    <Col xs={12} md={10} className="timeline-content">
                      <motion.div
                        initial="hidden"
                        animate={inView7 ? "visible" : "hidden"}
                        variants={fadeInUp}
                        transition={{ duration: 0.6, delay: 0.5 }}
                        ref={ref7}
                      >
                        Відновлення роботи в онлайн та офлайн форматах.
                      </motion.div>
                    </Col>
                  </Row>
                </li>
              </ul>
            </section>

            <section className="mb-3">
              <h2 className="text-center mb-4 section-title">
                Чому обирати Unext?
              </h2>
              <Row>
                <Col md={6} className="mb-4 text-center li-company-text">
                  <motion.div
                    initial="hidden"
                    animate={inView8 ? "visible" : "hidden"}
                    variants={fadeInUp}
                    transition={{ duration: 0.6, delay: 0.1 }}
                    ref={ref8}
                  >
                    <h3>Досвідчені викладачі</h3>
                    <p>
                      Наші викладачі - це висококваліфіковані фахівці з
                      багаторічним досвідом роботи. Вони знають, як зробити
                      навчання цікавим та ефективним, а також допоможуть вам
                      підготуватися до НМТ та ДПА на найвищому рівні.
                    </p>
                  </motion.div>
                </Col>
                <Col md={6} className="mb-4 text-center li-company-text">
                  <motion.div
                    initial="hidden"
                    animate={inView9 ? "visible" : "hidden"}
                    variants={fadeInUp}
                    transition={{ duration: 0.6, delay: 0.2 }}
                    ref={ref9}
                  >
                    <h3>Індивідуальний підхід</h3>
                    <p>
                      Ми пропонуємо різні формати навчання, щоб ви могли обрати
                      той, який найкраще відповідає вашим потребам та
                      можливостям. Ви можете навчатися в групі, онлайн або на
                      індивідуальних уроках.
                    </p>
                  </motion.div>
                </Col>
                <Col md={6} className="mb-4 text-center li-company-text">
                  <motion.div
                    initial="hidden"
                    animate={inView10 ? "visible" : "hidden"}
                    variants={fadeInUp}
                    transition={{ duration: 0.6, delay: 0.3 }}
                    ref={ref10}
                  >
                    <h3>Доступні ціни</h3>
                    <p>
                      Ми прагнемо зробити якісну освіту доступною для всіх, тому
                      пропонуємо доступні ціни на наші послуги. Вартість наших
                      курсів значно нижча, ніж у багатьох інших навчальних
                      закладах, при цьому ми гарантуємо високу якість навчання.
                    </p>
                  </motion.div>
                </Col>
                <Col md={6} className="mb-4 text-center li-company-text">
                  <motion.div
                    initial="hidden"
                    animate={inView11 ? "visible" : "hidden"}
                    variants={fadeInUp}
                    transition={{ duration: 0.6, delay: 0.4 }}
                    ref={ref11}
                  >
                    <h3>Якісна та доступна освіта</h3>
                    <p>
                      Для нас важливо, щоб кожен учень мав можливість отримати
                      якісну освіту. Ми постійно вдосконалюємо наші методики
                      навчання та використовуємо найсучасніші технології, щоб
                      зробити процес навчання максимально ефективним.
                    </p>
                  </motion.div>
                </Col>
              </Row>
            </section>

            <section className="statistics-section-company">
              <Container>
                <Row className="justify-content-center">
                  <Col md={4} className="text-center">
                    <div className="statistics-item-company">
                      <motion.div
                        initial="hidden"
                        animate={inView1 ? "visible" : "hidden"}
                        variants={fadeInUp}
                        transition={{ duration: 0.6, delay: 0.1 }}
                      >
                        <span className="statistics-number-company">
                          <CountUp end={180} duration={3} />
                        </span>
                        <span className="statistics-text-company">
                          Середній бал
                        </span>
                      </motion.div>
                    </div>
                  </Col>
                  <Col md={4} className="text-center">
                    <div className="statistics-item-company">
                      <motion.div
                        initial="hidden"
                        animate={inView1 ? "visible" : "hidden"}
                        variants={fadeInUp}
                        transition={{ duration: 0.6, delay: 0.2 }}
                      >
                        <span className="statistics-number-company">
                          <CountUp end={3500} duration={3} />+
                        </span>
                        <span className="statistics-text-company">
                          Учнів підготовлено{" "}
                        </span>
                      </motion.div>
                    </div>
                  </Col>
                  <Col md={4} className="text-center">
                    <div className="statistics-item-company">
                      <motion.div
                        initial="hidden"
                        animate={inView1 ? "visible" : "hidden"}
                        variants={fadeInUp}
                        transition={{ duration: 0.6, delay: 0.3 }}
                      >
                        <span className="statistics-number-company">
                          <CountUp end={99} duration={3} suffix="%" />
                        </span>
                        <span className="statistics-text-company">
                          Задоволених клієнтів
                        </span>
                      </motion.div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="text-center mb-5 mt-4">
              <motion.h2
                initial="hidden"
                animate={inView12 ? "visible" : "hidden"}
                variants={fadeInUp}
                transition={{ duration: 0.6, delay: 0.1 }}
                ref={ref12}
                className="mb-4 section-title"
              >
                Освітній центр Unext запрошує на роботу!
              </motion.h2>
              <motion.p
                initial="hidden"
                animate={inView12 ? "visible" : "hidden"}
                variants={fadeInUp}
                transition={{ duration: 0.6, delay: 0.2 }}
                ref={ref12}
                className="text-job-company"
              >
                Ви хочете приєднатися до команди професіоналів, які роблять
                значний внесок у майбутнє української освіти?
              </motion.p>
              <motion.p
                initial="hidden"
                animate={inView12 ? "visible" : "hidden"}
                variants={fadeInUp}
                transition={{ duration: 0.6, delay: 0.3 }}
                ref={ref12}
                className="text-job-company"
              >
                Освітній центр Unext - це динамічно розвиваючийся освітній
                заклад, який пропонує якісну підготовку до НМТ та ДПА, а також
                інші освітні послуги. Ми пишаємося нашою командою досвідчених та
                відданих своїй справі викладачів.
              </motion.p>
              <Row className="mb-5">
                <Col xs={12} md={6}>
                  <motion.p
                    initial="hidden"
                    animate={inView12 ? "visible" : "hidden"}
                    variants={fadeInUp}
                    transition={{ duration: 0.6, delay: 0.4 }}
                    ref={ref12}
                    className="text-job-company-title centered-title"
                  >
                    Ми шукаємо:
                  </motion.p>
                  <motion.ul
                    initial="hidden"
                    animate={inView12 ? "visible" : "hidden"}
                    variants={fadeInUp}
                    transition={{ duration: 0.6, delay: 0.5 }}
                    ref={ref12}
                    className="list-unstyled text-li-job-company centered-list"
                  >
                    <motion.li className="text-job-company">
                      - Талановитих та мотивованих людей;
                    </motion.li>
                    <motion.li className="text-job-company">
                      - Стати частиною команди освітніх однодумців;
                    </motion.li>
                    <motion.li className="text-job-company">
                      - Допомагати учням досягати своїх цілей;
                    </motion.li>
                    <motion.li className="text-job-company">
                      - Розвивати свою кар'єру в динамічному та інноваційному
                      середовищі.
                    </motion.li>
                  </motion.ul>
                </Col>
                <Col xs={12} md={6}>
                  <motion.p
                    initial="hidden"
                    animate={inView12 ? "visible" : "hidden"}
                    variants={fadeInUp}
                    transition={{ duration: 0.6, delay: 0.4 }}
                    ref={ref12}
                    className="text-job-company-title centered-title"
                  >
                    Якщо ви володієте:
                  </motion.p>
                  <motion.ul
                    initial="hidden"
                    animate={inView12 ? "visible" : "hidden"}
                    variants={fadeInUp}
                    transition={{ duration: 0.6, delay: 0.5}}
                    ref={ref12}
                    className="list-unstyled text-li-job-company centered-list"
                  >
                    <motion.li className="text-job-company">
                      - Високим рівнем знань з предметів, які викладаєте;
                    </motion.li>
                    <motion.li className="text-job-company">
                      - Досвідом роботи в освіті (буде перевагою);
                    </motion.li>
                    <motion.li className="text-job-company">
                      - Відмінними комунікативними навичками;
                    </motion.li>
                    <motion.li className="text-job-company">
                      - Вмінням мотивувати та надихати учнів;
                    </motion.li>
                    <motion.li className="text-job-company">
                      - Бажанням постійно розвиватися та вдосконалюватися.
                    </motion.li>
                  </motion.ul>
                </Col>
              </Row>
              <motion.p
                    initial="hidden"
                    animate={inView12 ? "visible" : "hidden"}
                    variants={fadeInUp}
                    transition={{ duration: 0.6, delay: 0.6 }}
                    ref={ref12}
                    className="text-job-company-meet"
                  >
                    Тоді ми запрошуємо вас надіслати своє резюме на{" "}
                <a href="mailto:unext2024unext@gmail.com">адресу</a>
                  </motion.p>
              
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Company;
