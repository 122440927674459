import React, { useState, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import { RemoveScroll } from "react-remove-scroll";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ChemSub from "../Images/ChemSub.png";
import GeogSub from "../Images/GeogSub.png";
import MathSub from "../Images/MathSub.png";
import UkLangSub from "../Images/UkLangSub.png";
import BioSub from "../Images/BioSub.png";
import HistorySub from "../Images/HistorySub.png";
import PhysSub from "../Images/PhysSub.png";
import EngSub from "../Images/EngSub.png";
import emailjs from "emailjs-com";
import LogoItem from "../Images/LogoItem.png";
import "../Style/Subjects.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Subjects = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [isForm, setIsForm] = useState(false);
  const [, setDropdownOpen] = useState(false);
  const formRef = useRef();
  const modalRef = useRef();

  useEffect(() => {
    AOS.init({
      duration: 500, // Длительность анимации
      once: true, // Анимация срабатывает только один раз
    });
  }, []);

  const subjectsData = {
    Українська_мова: {
      title: "Українська мова",
      description1: `
      Чому важливо знати українську мову?
      `,
      points1: [
        "- Українська мова - це державна мова України, знання якої є необхідною умовою для життя, навчання та роботи в державі.        ",
        "- Українська мова багата своєю глибокою історією та культурою. Знання української мови дозволяє краще розуміти український народ, його традиції та цінності.        ",
        "- Українська мова - це ключ до успішного навчання у закладах вищої освіти.",
        "- Володіння українською мовою високо цінується роботодавцями в різних сферах діяльності.",
      ],
      description2: `
      Що вивчають на курсах української мови Unext?
      `,
      points2: [
        "- Фонетика, графіка, орфоепія, орфографія: вивчення звукового складу української мови, правил написання слів, правильного вимовлення слів та речень.        ",
        "- Лексикологія, фразеологія: вивчення словникового складу української мови, значення слів та стійких словосполучень, походження слів.        ",
        "- Будова слова, словотвір: вивчення будови слова, способів словотворення.        ",
        "- Морфологія: вивчення частин мови, їх граматичних ознак та граматичних форм.        ",
        "- Синтаксис: вивчення будови словосполучень та речень, їх видів та функцій.        ",
        "- Стилістика: вивчення стилів мови, їх особливостей та вживання.        ",
        "- Розвиток мовлення: удосконалення навичок усного та писемного мовлення, вміння висловлювати свої думки та ідеї чітко, логічно та стилістично правильно.        ",
      ],
      description3: `
      Переваги курсів української мови Unext:
      `,
      points3: [
        "- Досвідчені викладачі: наші викладачі - це кваліфіковані фахівці з багаторічним досвідом роботи. Вони знають, як зробити навчання цікавим та ефективним.        ",
        "- Інтерактивні методи навчання: ми використовуємо сучасні методи навчання, які роблять процес навчання динамічним та захоплюючим",
        "- Зручний розклад занять: ми пропонуємо гнучкий розклад занять, який дозволить вам вчитися в зручний для вас час.        ",
        "- Доступні ціни: ми пропонуємо  оптимальні ціни на наші курси, щоб зробити якісну освіту доступною для всіх.        ",
      ],
      description4: `
      Не пропустіть цю можливість покращити свої знання української мови та підготуватися до НМТ та ДПА на високому рівні!
      `,
      img: UkLangSub,
    },
    Математика: {
      title: "Математика",
      description1: `
      Чому важливо знати математику?
      `,
      points1: [
        "-  Математика - це фундаментальна наука, яка лежить в основі багатьох інших наук, таких як фізика, хімія, біологія, економіка, інженерія та комп'ютерні науки.        ",
        "- Математичні знання та навички необхідні для вирішення багатьох проблем повсякденного життя, таких як планування бюджету, розрахунок відсотків, порівняння цін, читання графіків та діаграм.        ",
        "- Високий рівень володіння математикою - це обов'язкова умова для вступу на багато престижних спеціальностей, таких як програмування, фінанси, інженерія, медицина.        ",
        "- Володіння математикою високо цінується роботодавцями в різних сферах діяльності.        ",
      ],
      description2: `
      Що вивчають на курсах математики Unext?
      `,
      points2: [
        "- Арифметика: додавання, віднімання, множення, ділення чисел, дроби, відсотки, пропорції.        ",
        "- Алгебра: рівняння, нерівності, системи рівнянь, нерівностей, функції, графіки функцій.        ",
        "- Геометрія: точки, прямі, площини, фігури, кути, вектори, площі, об'єми.        ",
        "- Тригонометрія: синуси, косинуси, тангенси, котангенси, арксинуси, арккосинуси, арктангенси, арккотангенси.        ",
        "- Комбінаторика: ймовірність, статистика, комбінації, перестановки, біном Ньютона.        ",
      ],
      description3: `
      Переваги курсів математики Unext:
      `,
      points3: [
        "- Досвідчені викладачі: наші викладачі - це кваліфіковані фахівці з багаторічним досвідом роботи. Вони знають, як зробити навчання цікавим та ефективним.        ",
        "- Інтерактивні методи навчання: ми використовуємо сучасні методи навчання, які роблять процес навчання динамічним та захоплюючим.        ",
        "- Зручний розклад занять: ми пропонуємо гнучкий розклад занять, який дозволить вам вчитися в зручний для вас час.        ",
        "- Доступні ціни: ми пропонуємо доступні ціни на наші курси, щоб зробити якісну освіту доступною для всіх.        ",
      ],
      description4: `
      Не пропустіть цю можливість покращити свої знання математики та підготуватися до НМТ та ДПА на високому рівні!
      `,
      img: MathSub,
    },
    Історія_України: {
      title: "Історія України",
      description1: `
      Чому важливо знати історію України?
      `,
      points1: [
        "- Історія України -  це жива розповідь про життя нашого народу, про його здобутки та втрати, про його боротьбу за свободу та незалежність.        ",
        "- Знання історії України дозволяє нам краще зрозуміти сьогодення та перспективи розвитку нашої країни.        ",
        "- Вивчення історії України - це наш обов'язок перед пам'яттю наших предків.        ",
      ],
      description2: `
      Що вивчають на курсах історії України Unext?
      `,
      points2: [
        "- Ранньомодерна історія України: вивчення козацької держави, Гетьманщини, Речі Посполитої, Російської імперії.        ",
        "- Новітня історія України: вивчення Української революції 1917-1921 рр., радянського періоду, Другої світової війни, Української незалежності.        ",
        "- Сучасна історія України: вивчення подій після 1991 року, становлення незалежної Української держави.        ",
      ],
      description3: `
      Переваги курсів історії України Unext:
      `,
      points3: [
        "- Досвідчені викладачі: наші викладачі - це кваліфіковані фахівці з багаторічним досвідом роботи. Вони знають, як зробити навчання цікавим та ефективним.        ",
        "- Інтерактивні методи навчання: ми використовуємо сучасні методи навчання, які роблять процес навчання динамічним та захоплюючим.        ",
        "- Використання мультимедійних засобів: ми використовуємо відео, аудіо, фотографії та інші мультимедійні засоби, щоб зробити навчання більш наочним та цікавим.        ",
        "- Зручний розклад занять: ми пропонуємо гнучкий розклад занять, який дозволить вам вчитися в зручний для вас час.        ",
        "- Доступні ціни: ми пропонуємо доступні ціни на наші курси, щоб зробити якісну освіту доступною для всіх.        ",
      ],
      description4: `
      Не пропустіть цю можливість покращити свої знання історії України та краще зрозуміти свою країну!
      `,
      img: HistorySub,
    },
    Іноземна_мова: {
      title: "Іноземна мова",
      description1: `
      Чому важливо знати іноземну мову?
      `,
      points1: [
        "- Іноземна мова - це ключ до спілкування з людьми з усього світу. Знання іноземної мови дозволяє вам подорожувати, знаходити друзів, вести ділові переговори, навчатися за кордоном.        ",
        "- Володіння іноземною мовою високо цінується роботодавцями в різних сферах діяльності.        ",
        "- Знання іноземної мови розширює ваш кругозір, знайомить вас з іншими культурами та цінностями.        ",
      ],
      description2: `
      Що вивчають на курсах іноземної мови Unext?
      `,
      points2: [
        "- Граматика: вивчення граматичних правил мови, відмінків, часів, застав, способів дієслів, синтаксичних конструкцій.        ",
        "- Лексика: вивчення слів та словосполучень, розширення словникового запасу, вивчення ідіом та фразеології.",
        "- Фонетика: вивчення звукового складу мови, правил вимови, інтонації. ",
        "- Читання: вивчення різних текстів, таких як новинні статті, художня література, наукові тексти.        ",
        "- Аудіювання: розвиток навичок розуміння усної мови, прослуховування аудіозаписів, діалогів, лекцій.        ",
        "- Говоріння: розвиток навичок усного мовлення, ведення діалогів, висловлювання думок та ідей.        ",
        "- Письмо: розвиток навичок письмового мовлення, написання листів, есе, статей.        ",
      ],
      description3: `
      Переваги курсів іноземної мови Unext:
      `,
      points3: [
        "- Досвідчені викладачі: наші викладачі - це носії мови з багаторічним досвідом роботи. Вони знають, як зробити навчання цікавим та ефективним.        ",
        "- Комунікативна методика навчання: ми використовуємо комунікативну методику навчання, яка робить акцент на практичному використанні мови.        ",
        "- Інтерактивні методи навчання: ми використовуємо сучасні методи навчання, які роблять процес навчання динамічним та захоплюючим.        ",
        "- Використання мультимедійних засобів: ми використовуємо відео, аудіо, фотографії та інші мультимедійні засоби, щоб зробити навчання більш наочним та цікавим.        ",
        "- Зручний розклад занять: ми пропонуємо гнучкий розклад занять, який дозволить вам вчитися в зручний для вас час.        ",
        "- Доступні ціни: ми пропонуємо доступні ціни на наші курси, щоб зробити якісну освіту доступною для всіх.        ",
      ],
      description4: `
      Не пропустіть цю можливість покращити свої знання іноземної мови та відкрити нові можливості для себе!
      `,
      img: EngSub,
    },
    Географія: {
      title: "Географія",
      description1: `
      Чому важливо знати географію?
      `,
      points1: [
        "- Географія - це наука про Землю, її природу та населення.",
        "- Знання географії необхідні для вирішення багатьох проблем повсякденного життя, таких як планування подорожей, вибір місця проживання, розуміння кліматичних змін.        ",
        "- Високий рівень володіння географією - це обов'язкова умова для вступу на багато престижних спеціальностей, таких як геологія, географія, екологія, туризм.        ",
        "- Знання основ географії високо цінується роботодавцями в різних сферах діяльності.        ",
      ],
      description2: `
      Що вивчають на курсах географії Unext?
      `,
      points2: [
        "- Загальна географія: вивчення загальних законів розвитку природи, географічної оболонки Землі, її компонентів.        ",
        "- Фізична географія: вивчення природи Землі, її рельєфу, клімату, водних ресурсів, ґрунтів, рослинного та тваринного світу.        ",
        "- Економічна та соціальна географія: вивчення господарської діяльності людини, розміщення населення, промисловості, сільського господарства, транспорту, торгівлі.        ",
        "- Географія України: вивчення природних умов та ресурсів України, її населення, господарства, регіонів.        ",
        "- Географія світу: вивчення природних умов та ресурсів світу, його населення, господарства, регіонів.        ",
      ],
      description3: `
      Переваги курсів географії Unext:
      `,
      points3: [
        "- Досвідчені викладачі: наші викладачі - це кваліфіковані фахівці з багаторічним досвідом роботи. Вони знають, як зробити навчання цікавим та ефективним.        ",
        "- Інтерактивні методи навчання: ми використовуємо сучасні методи навчання, які роблять процес навчання динамічним та захоплюючим.        ",
        "- Використання мультимедійних засобів: ми використовуємо відео, аудіо, фотографії, карти та інші мультимедійні засоби, щоб зробити навчання більш наочним та цікавим.        ",
        "- Практичні заняття: ми проводимо практичні заняття, на яких ви зможете закріпити свої знання та навички.        ",
        "- Зручний розклад занять: ми пропонуємо гнучкий розклад занять, який дозволить вам вчитися в зручний для вас час.        ",
        "- Доступні ціни: ми пропонуємо доступні ціни на наші курси, щоб зробити якісну освіту доступною для всіх.        ",
      ],
      description4: `
      Не пропустіть цю можливість покращити свої знання географії та краще зрозуміти світ навколо вас!
      `,
      img: GeogSub,
    },
    Фізика: {
      title: "Фізика",
      description1: `
      Чому важливо знати фізику?
      `,
      points1: [
        "- Фізика - це фундаментальна наука, яка вивчає закони природи. Знання фізики дозволяє нам краще зрозуміти світ навколо нас, його явища та процеси.        ",
        "- Знання фізики необхідні для багатьох професій, таких як інженер, лікар, програміст, науковець.        ",
        "- Високий рівень володіння фізикою - це обов'язкова умова для вступу на багато престижних спеціальностей, таких як фізика, математика, хімія, інженерія.        ",
        "- Володіння фізикою високо цінується роботодавцями в різних сферах діяльності.        ",
      ],
      description2: `
      Що вивчають на курсах фізики Unext?
      `,
      points2: [
        "- Механіка: вивчення законів механічного руху тіл, законів збереження енергії та імпульсу.        ",
        "- Молекулярна фізика та термодинаміка: вивчення будови та властивостей речовини, законів термодинаміки.        ",
        "- Електродинаміка: вивчення електричних та магнітних явищ, законів електромагнетизму.        ",
        "- Коливання і хвилі: вивчення коливань, механічних та електромагнітних хвиль.        ",
        "- Оптика: вивчення світла, його властивостей та законів поширення.        ",
        "- Атомна та ядерна фізика: вивчення будови атомів та ядер, атомних та ядерних явищ.        ",
        "- Основи сучасної фізики: вивчення теорії відносності, квантової механіки, фізики елементарних частинок.        ",
      ],
      description3: `
      Переваги курсів фізики Unext:
      `,
      points3: [
        "- Досвідчені викладачі: наші викладачі - це кваліфіковані фахівці з багаторічним досвідом роботи. Вони знають, як зробити навчання цікавим та ефективним.        ",
        "- Інтерактивні методи навчання: ми використовуємо сучасні методи навчання, які роблять процес навчання динамічним та захоплюючим.        ",
        "- Використання мультимедійних засобів: ми використовуємо відео, аудіо, фотографії, комп'ютерні моделі та інші мультимедійні засоби, щоб зробити навчання більш наочним та цікавим.        ",
        "- Практичні заняття: ми проводимо практичні заняття, на яких ви зможете закріпити свої знання та навички.        ",
        "- Зручний розклад занять: ми пропонуємо гнучкий розклад занять, який дозволить вам вчитися в зручний для вас час.        ",
        "- Доступні ціни: ми пропонуємо доступні ціни на наші курси, щоб зробити якісну освіту доступною для всіх.        ",
      ],
      description4: `
      Не пропустіть цю можливість покращити свої знання фізики та краще зрозуміти світ навколо вас!
      `,
      img: PhysSub,
    },
    Хімія: {
      title: "Хімія",
      description1: `
      Чому важливо знати хімію?
      `,
      points1: [
        "- Хімія - це наука про речовину, її склад, будову, властивості та перетворення. Знання хімії дозволяє нам краще зрозуміти світ навколо нас, його явища та процеси.        ",
        "- Знання хімії необхідні для багатьох професій, таких як лікар, фармацевт, хімік, інженер, еколог.        ",
        "- Високий рівень володіння хімією - це обов'язкова умова для вступу на багато престижних спеціальностей, таких як хімія, біологія, медицина, фармація.        ",
        "- Володіння хімією високо цінується роботодавцями в різних сферах діяльності.        ",
      ],
      description2: `
      Що вивчають на курсах хімії Unext?
      `,
      points2: [
        "- Загальна хімія: вивчення основних законів хімії, будови речовини, хімічних реакцій.        ",
        "- Неорганічна хімія: вивчення хімії елементів та їх сполук.        ",
        "- Органічна хімія: вивчення хімії органічних сполук.        ",
        "- Аналитична хімія: вивчення методів якісного та кількісного аналізу речовин.        ",
        "- Фізична хімія: вивчення загальних законів хімії, які ґрунтуються на законах фізики.        ",
        "- Хімія біологічних систем: вивчення хімії біологічних молекул та процесів, що відбуваються в живих організмах.        ",
      ],
      description3: `
      Переваги курсів хімії Unext:
      `,
      points3: [
        "- Досвідчені викладачі: наші викладачі - це кваліфіковані фахівці з багаторічним досвідом роботи. Вони знають, як зробити навчання цікавим та ефективним.        ",
        "- Інтерактивні методи навчання: ми використовуємо сучасні методи навчання, які роблять процес навчання динамічним та захоплюючим.        ",
        "- Використання мультимедійних засобів: ми використовуємо відео, аудіо, фотографії, комп'ютерні моделі та інші мультимедійні засоби, щоб зробити навчання більш наочним та цікавим.        ",
        "- Практичні заняття: ми проводимо практичні заняття, на яких ви зможете закріпити свої знання та навички.        ",
        "- Зручний розклад занять: ми пропонуємо гнучкий розклад занять, який дозволить вам вчитися в зручний для вас час.        ",
        "- Доступні ціни: ми пропонуємо доступні ціни на наші курси, щоб зробити якісну освіту доступною для всіх.        ",
      ],
      description4: `
      Не пропустіть цю можливість покращити свої знання хімії та краще зрозуміти світ навколо вас!
      `,
      img: ChemSub,
    },
    Біологія: {
      title: "Біологія",
      description1: `
      Чому важливо знати біологію?
      `,
      points1: [
        "- Біологія - це наука про життя, його походження, розвиток, будову та функціонування. Знання біології дозволяє нам краще зрозуміти себе, інші живі організми та світ навколо нас.        ",
        "- Знання біології необхідні для багатьох професій, таких як лікар, біолог, фармацевт, еколог, ветеринар.        ",
        "- Високий рівень володіння біологією - це обов'язкова умова для вступу на багато престижних спеціальностей, таких як біологія, медицина, фармація, ветеринарія.        ",
        "- Володіння біологією високо цінується роботодавцями в різних сферах діяльності.        ",
      ],
      description2: `
      Що вивчають на курсах біології Unext? 
      `,
      points2: [
        "- Загальна біологія: вивчення основних законів життя, будови та функціонування клітин, еволюції живих організмів, генетики, екології.        ",
        "- Ботаніка: вивчення рослинного світу, його будови, функціонування, розмноження та еволюції.        ",
        "- Зоологія: вивчення тваринного світу, його будови, функціонування, розмноження та еволюції.        ",
        "- Анатомія та фізіологія людини: вивчення будови та функціонування організму людини.        ",
        "- Мікробіологія: вивчення мікроорганізмів, їх будови, функціонування та ролі в житті людини.        ",
        "- Біохімія: вивчення хімічного складу та хімічних процесів, що відбуваються в живих організмах.        ",
      ],
      description3: `
      Переваги курсів біології Unext:
      `,
      points3: [
        "- Досвідчені викладачі: наші викладачі - це кваліфіковані фахівці з багаторічним досвідом роботи. Вони знають, як зробити навчання цікавим та ефективним.        ",
        "- Інтерактивні методи навчання: ми використовуємо сучасні методи навчання, які роблять процес навчання динамічним та захоплюючим.        ",
        "- Використання мультимедійних засобів: ми використовуємо відео, аудіо, фотографії, комп'ютерні моделі та інші мультимедійні засоби, щоб зробити навчання більш наочним та цікавим.        ",
        "- Практичні заняття: ми проводимо практичні заняття, на яких ви зможете закріпити свої знання та навички.        ",
        "- Зручний розклад занять: ми пропонуємо гнучкий розклад занять, який дозволить вам вчитися в зручний для вас час.        ",
        "- Доступні ціни: ми пропонуємо доступні ціни на наші курси, щоб зробити якісну освіту доступною для всіх.        ",
      ],
      description4: `
      Не пропустіть цю можливість покращити свої знання біології та краще зрозуміти світ навколо вас!
      `,
      img: BioSub,
    },
  };

  const [formStatus] = useState(""); // Добавляем новое состояние
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const handlePhoneChange = (value) => {
    setPhone(value);
    if (value) {
      setPhoneError("");
    }
  };

  const handlePhoneBlur = () => {
    if (!phone) {
      setPhoneError("Будь ласка, введіть номер телефону.");
    }
  };

  const handlePhoneFocus = () => {
    setPhoneError("");
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Закрываем меню, если клик был совершен вне области модального окна
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleShowModal = (subject) => {
    setModalContent(subjectsData[subject]);
    setIsForm(false);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowForm = () => {
    setIsForm(true);
  };

  // ------------------------------
  // Отримуємо utm-мітки з URL параметрів 
  const getUTMParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);

    let utm_source, utm_medium, utm_campaign, utm_content, utm_term;

    if (urlParams.has('utm_source')) { utm_source = urlParams.get('utm_source') } else { utm_source = "" };
    if (urlParams.has('utm_medium')) { utm_medium = urlParams.get('utm_medium') } else { utm_medium = "" };
    if (urlParams.has('utm_campaign')) { utm_campaign = urlParams.get('utm_campaign') } else { utm_campaign = "" };
    if (urlParams.has('utm_content')) { utm_content = urlParams.get('utm_content') } else { utm_content = "" };
    if (urlParams.has('utm_term')) { utm_term = urlParams.get('utm_term') } else { utm_term = "" };

    return {
      "utm_source": utm_source,
      "utm_medium": utm_medium,
      "utm_campaign": utm_campaign,
      "utm_content": utm_content,
      "utm_term": utm_term
    };
  };
  // ---------------------------------- By Mykyta

  const handleSubmitForm = (event) => {
    event.preventDefault();
    if (phone.trim() === "") {
      setPhoneError("Будь ласка, введіть номер телефону");
      return;
    }

    setPhoneError(""); // Очистить ошибку, если все в порядке
    console.log("Form submitted"); // Поставьте сюда ваш код отправки данных формы

    const formData = new FormData(formRef.current);
    const data = {
      name: formData.get("name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
    };

    emailjs
      .send("service_5gqx2v7", "template_espmo1a", data, "4upStKj7ORtxgJNce")
      .then((response) => {
        toast.success("Повідомлення успішно відправлено!"); // Показать уведомление об успешной отправке
        handleCloseModal(); // Закрыть модальное окно сразу после успешной отправки
      })
      .catch((error) => {
        toast.error("Помилка надсилання повідомлення, спробуйте ще раз."); // Показать уведомление об ошибке
      });

    // -------------------------------
    const utmData = getUTMParameters();

    // Дані для відправки у форматі JSON
    const crmTgData = {
      event: event.type,
      button_id: event.target.id,
      timestamp: new Date().toISOString(),
      name: formData.get("name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
      utm_source: utmData.utm_source,
      utm_medium: utmData.utm_medium,
      utm_campaign: utmData.utm_campaign,
      utm_content: utmData.utm_content,
      utm_term: utmData.utm_term
    };

    // Відправка даних за допомогою fetch
    fetch('https://red.bidata.store/unext/landing/lead/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(crmTgData)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(crmTgData => {
        console.log('Success');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    // ------------------------------ By Mykyta
    
      handleCloseModal(); // Закрыть модальное окно независимо от результата отправки
  };

  return (
    <Container fluid className="py-5">
      <ToastContainer />
      <div className="section-header-subjects text-center mb-5">
        <h2 className="section-title-subjects">Предмети</h2>
      </div>
      <Row className="subjects-grid">
        {Object.keys(subjectsData).map((key, index) => (
          <Col
            md={3}
            sm={6}
            className="text-center subject-block"
            key={key}
            data-aos="fade-up"
          >
            <img
              src={subjectsData[key].img}
              alt="Иконка"
              className="feature-icon-subjects"
              onClick={() => handleShowModal(key)}
              style={{ cursor: "pointer" }}
            />
            <div className="text-left">
              <h5
                className="feature-title-subjects"
                onClick={() => handleShowModal(key)}
                style={{ cursor: "pointer" }}
              >
                {subjectsData[key].title}
              </h5>
            </div>
          </Col>
        ))}
      </Row>

      <RemoveScroll enabled={showModal}>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          size={isForm ? "lg" : "xl"}
          dialogClassName={isForm ? "custom-form-modal-dialog" : ""}
          centered
        >
          <div className="custom-modal-content">
            <Modal.Header
              closeButton
              className="modal-header-subjects custom-modal-header"
            >
              <Modal.Title>
                {isForm ? (
                  <Modal.Title className="titleModal">
                    Записатися на курс &nbsp;
                    <img
                      src={LogoItem}
                      className="logoAfterText"
                      alt="LogoItem"
                    />
                  </Modal.Title>
                ) : (
                  <div className="d-flex align-items-center">
                    <img
                      src={modalContent.img}
                      alt="Логотип предмета"
                      style={{ width: "40px", marginRight: "10px" }}
                    />
                    {modalContent.title}
                  </div>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              className={`custom-modal-body ${
                isForm ? "" : "modal-body-scrollable"
              } ${!isForm ? "custom-info-modal-body" : ""}`}
            >
              {isForm ? (
                <Form ref={formRef} onSubmit={handleSubmitForm}>
                  <Form.Group controlId="formBasicName">
                    <Form.Label className="textModal">Ваше імʼя:</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Будь ласка, введіть ваше ім'я"
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPhone">
                    <Form.Label className="textModal">
                      Номер телефону:
                    </Form.Label>
                    <PhoneInput
                      country={"ua"}
                      value={phone}
                      onChange={handlePhoneChange}
                      onBlur={handlePhoneBlur}
                      onFocus={handlePhoneFocus}
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: false,
                        className: "phone-input-field",
                      }}
                      containerClass="phone-input-container"
                    />
                    {phoneError && (
                      <div className="text-danger">{phoneError}</div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="textModal">
                      Електронна адреса:
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Будь ласка, введіть електронну адресу"
                    />
                  </Form.Group>
                  {formStatus && <p>{formStatus}</p>}{" "}
                  <Form.Group controlId="dataProcessingAgreement">
                <Form.Check
                  type="checkbox"
                  label="Натискаючи кнопку, я даю згоду на обробку персональних даних."
                  required
                />
              </Form.Group>
                  {/* Отображаем сообщение пользователю */}
                  <Modal.Footer className="custom-modal-footer-subjects">
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Закрити
                    </Button>
                    <Button type="submit" variant="secondary">
                      Відправити
                    </Button>
                  </Modal.Footer>
                </Form>
              ) : (
                <>
                  <p>{modalContent.description1}</p>
                  <ul>
                    {modalContent.points1?.map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                  <p>{modalContent.description2}</p>
                  <ul>
                    {modalContent.points2?.map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                  <p>{modalContent.description3}</p>
                  <ul>
                    {modalContent.points3?.map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                  <p>{modalContent.description4}</p>
                </>
              )}
            </Modal.Body>
            {!isForm && (
              <Modal.Footer className="custom-modal-footer-subjects">
                <Button variant="secondary" onClick={handleCloseModal}>
                  Закрити
                </Button>
                <Button variant="secondary" onClick={handleShowForm}>
                  Записатися на курс
                </Button>
              </Modal.Footer>
            )}
          </div>
        </Modal>
      </RemoveScroll>
    </Container>
  );
};

export default Subjects;
