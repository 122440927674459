import React, { useState, useRef } from "react";
import { Container, Modal, Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { RemoveScroll } from "react-remove-scroll";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import emailjs from "emailjs-com";
import "bootstrap/dist/css/bootstrap.min.css";
import LogoBlack from "../Images/LogoBlack.png";
import LogoItem from "../Images/LogoItem.png";
import "../Style/Contacts.css"; // Создайте этот файл для пользовательских стилей
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Contacts = () => {
  const [show, setShow] = useState(false);
  const form = useRef();
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");


  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2, // В какой момент анимация должна начаться (0 - при первом появлении, 1 - при полном появлении)
  });
  

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handlePhoneChange = (value) => {
    setPhone(value);
    if (value) {
      setPhoneError("");
    }
  };

  const handlePhoneBlur = () => {
    if (!phone) {
      setPhoneError("Будь ласка, введіть номер телефону.");
    }
  };

  const handlePhoneFocus = () => {
    setPhoneError("");
  };

  // ------------------------------
  // Отримуємо utm-мітки з URL параметрів 
  const getUTMParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);

    let utm_source, utm_medium, utm_campaign, utm_content, utm_term;

    if (urlParams.has('utm_source')) { utm_source = urlParams.get('utm_source') } else { utm_source = "" };
    if (urlParams.has('utm_medium')) { utm_medium = urlParams.get('utm_medium') } else { utm_medium = "" };
    if (urlParams.has('utm_campaign')) { utm_campaign = urlParams.get('utm_campaign') } else { utm_campaign = "" };
    if (urlParams.has('utm_content')) { utm_content = urlParams.get('utm_content') } else { utm_content = "" };
    if (urlParams.has('utm_term')) { utm_term = urlParams.get('utm_term') } else { utm_term = "" };

    return {
      "utm_source": utm_source,
      "utm_medium": utm_medium,
      "utm_campaign": utm_campaign,
      "utm_content": utm_content,
      "utm_term": utm_term
    };
  };
  // ---------------------------------- By Mykyta

  const handleSubmit = (event) => {
    event.preventDefault();
    if (phone.trim() === "") {
      setPhoneError("Будь ласка, введіть номер телефону");
      return;
    }

    setPhoneError(""); // Очистить ошибку, если все в порядке
    console.log("Form submitted"); // Поставьте сюда ваш код отправки данных формы

    const formData = new FormData(form.current);
    const data = {
      name: formData.get("name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
    };

    emailjs
      .send("service_5gqx2v7", "template_vob4knt", data, "4upStKj7ORtxgJNce")
      .then((response) => {
        toast.success("Повідомлення успішно відправлено!"); // Показать уведомление об успешной отправке
        handleClose(); // Закрыть модальное окно сразу после успешной отправки
      })
      .catch((error) => {
        toast.error("Помилка надсилання повідомлення, спробуйте ще раз."); // Показать уведомление об ошибке
      });

    // -------------------------------
    const utmData = getUTMParameters();

    // Дані для відправки у форматі JSON
    const crmTgData = {
      event: event.type,
      button_id: event.target.id,
      timestamp: new Date().toISOString(),
      name: formData.get("name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
      utm_source: utmData.utm_source,
      utm_medium: utmData.utm_medium,
      utm_campaign: utmData.utm_campaign,
      utm_content: utmData.utm_content,
      utm_term: utmData.utm_term
    };

    // Відправка даних за допомогою fetch
    fetch('https://red.bidata.store/unext/landing/lead/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(crmTgData)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(crmTgData => {
        console.log('Success');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    // ------------------------------ By Mykyta

    handleClose(); // Закрыть модальное окно независимо от результата отправки
  };

  return (
    <Container fluid className="mt-5">
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
        className="row align-items-center contacts-section"
      >
        <div className="col-md-8">
          <div className="contacts-header">Запрошуємо вас на пробне заняття!</div>
          <div className="contacts-text">
          Це чудова можливість познайомитися з викладачем та методикою викладання, отримати уявлення про формат уроку та як буд проходити процес навчання. І звісно, відчути атмосферу нашого центру та переконатися, що це місце, де Ваша дитина може досягти успіху.
          </div>
          <Button
            variant="secondary"
            className="btn-contacts"
            onClick={handleShow}
          >
            Пробне заняття
          </Button>
        </div>
        <div className="col-md-4 logo-contacts">
          <img src={LogoBlack} alt="Contacts" className="contacts-image" />
        </div>
      </motion.div>


      {/* Modal section */}
      <RemoveScroll enabled={show}>
        <Modal show={show} onHide={handleClose} backdrop="true" centered>
          <Modal.Header closeButton>
            <Modal.Title className="titleModal">
              Записатися на курс &nbsp;
              <img src={LogoItem} className="logoAfterText" alt="LogoItem" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-MainPage">
            <Form ref={form} onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicName">
                <Form.Label className="textModal">Ваше імʼя:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Будь ласка, введіть ваше ім'я"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicPhone">
                    <Form.Label className="textModal">
                      Номер телефону:
                    </Form.Label>
                    <PhoneInput
                      country={"ua"}
                      value={phone}
                      onChange={handlePhoneChange}
                      onBlur={handlePhoneBlur}
                      onFocus={handlePhoneFocus}
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: false,
                        className: "phone-input-field",
                      }}
                      containerClass="phone-input-container"
                    />
                    {phoneError && (
                      <div className="text-danger">{phoneError}</div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                <Form.Label className="textModal">
                  Електронна адреса:
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Будь ласка, введіть електронну адресу"
                
                />
              </Form.Group>
              <Form.Group controlId="dataProcessingAgreement">
                <Form.Check
                  type="checkbox"
                  label="Натискаючи кнопку, я даю згоду на обробку персональних даних."
                  required
                />
              </Form.Group>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Закрити
                </Button>
                <Button type="submit" variant="secondary">
                  Відправити
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </RemoveScroll>
      <ToastContainer />
    </Container>
  );
};

export default Contacts;
