import React, { useState, useEffect } from "react";
import "../Style/MainContent.css";
import Subjects from "./Subjects";
import Format from "./Format";
import Price from "./Price";
import Contacts from "./Contacts";
import MapPage from "./MapPage";

function MainContent() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <section id="Компанія"></section>

      <section id="Предмети">
        <Subjects />
      </section>
      <section id="Формат підготовки">
        <Price />
        {isMobile ? <Format /> : <MapPage />}
      </section>
      <section id="Контакти">
        <Contacts />
      </section>
    </div>
  );
}

export default MainContent;
