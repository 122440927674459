import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import MainContent from "./Components/MainContent";
import MainPage from "./Components/MainPage";
import MainPage2 from "./Components/MainPage2";
import Footer from "./Components/Footer";
import Company from './Pages/Company';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <Router>
      <div id="top"></div>
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <MainPage />
            <div className="page-container">
              <MainPage2 />
              <MainContent />
            </div>
            <Footer />
          </>
        } />
        <Route path="/company" element={<Company />} />
      </Routes>
    </Router>
  );
}

export default App;
