import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import icon from "../Images/Icon.png";
import StarIcon from "../Images/StarIcon.png";
import StepsIcon from "../Images/StepsIcon.png";
import IndivIcon from "../Images/IndivIcon.png";
import PsyhologIcon from "../Images/PsyhologIcon.png";
import StudyIcon from "../Images/StudyIcon.png";
import "../Style/MainPage2.css"; // Импортируем файл со стилями

const FeatureItem = ({ imgSrc, title, text }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <Col
      ref={ref}
      md={4}
      sm={6}
      className={`text-center feature-item ${inView ? 'animate' : ''}`}
    >
      <img src={imgSrc} alt="Іконка" className="feature-icon" />
      <div className="text-left">
        <h5 className="feature-title">{title}</h5>
        <p className="feature-text">{text}</p>
      </div>
    </Col>
  );
};

const MainPage2 = () => {
  return (
    <Container fluid className="py-5">
      <div className="section-header text-center mb-5">
        <h2 className="section-title">Підхід до навчання</h2>
      </div>
      <Row className="gy-4">
        <FeatureItem
          imgSrc={icon}
          title="Кваліфіковані викладачі"
          text="Викладачі з досвідом підготовки до НМТ, які добре знають вимоги та структуру тесту."
        />
        <FeatureItem
          imgSrc={StarIcon}
          title="Оцінювання знань"
          text="Проведення власного пробного НМТ, проміжних тестів, моніторинг прогресу та корегування плану навчання."
        />
        <FeatureItem
          imgSrc={StudyIcon}
          title="Інтерактивні методи"
          text="Використання мультимедійних матеріалів, таких як відео, презентації та інтерактивні вправи."
        />
        <FeatureItem
          imgSrc={IndivIcon}
          title="Індивідуалізація навчання"
          text="Вступне тестування на початку курсу для визначення рівня знань учня та виявлення прогалин."
        />
        <FeatureItem
          imgSrc={PsyhologIcon}
          title="Психологічна підготовка"
          text="Проведення тренінгів зі зменшення стресу та підвищення впевненості у власних силах."
        />
        <FeatureItem
          imgSrc={StepsIcon}
          title="Підтримка та мотивація"
          text="Регулярне консультування та підтримка з боку викладачів та менеджерів. Система нагород та визнання успіхів."
        />
      </Row>
    </Container>
  );
};

export default MainPage2;
