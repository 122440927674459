import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";
import { Modal, Button, Form, Container, Row, Col } from "react-bootstrap";
import { RemoveScroll } from "react-remove-scroll";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LogoItem from "../Images/LogoItem.png";
import "../Style/MainPage.css"; // Подключаем стили для MainPage
import CountUp from "react-countup";

const MainPage = () => {
  const [show1, setShow1] = useState(false);
  const [phone, setPhone] = useState("");
  const form = useRef();
  const [phoneError, setPhoneError] = useState("");

  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  
  const handlePhoneChange = (value) => {
    setPhone(value);
    if (value) {
      setPhoneError("");
    }
  };

  const handlePhoneBlur = () => {
    if (!phone) {
      setPhoneError("Будь ласка, введіть номер телефону.");
    }
  };

  const handlePhoneFocus = () => {
    setPhoneError("");
  };

  // ------------------------------
  // Отримуємо utm-мітки з URL параметрів 
  const getUTMParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);

    let utm_source, utm_medium, utm_campaign, utm_content, utm_term;

    if (urlParams.has('utm_source')) { utm_source = urlParams.get('utm_source') } else { utm_source = "" };
    if (urlParams.has('utm_medium')) { utm_medium = urlParams.get('utm_medium') } else { utm_medium = "" };
    if (urlParams.has('utm_campaign')) { utm_campaign = urlParams.get('utm_campaign') } else { utm_campaign = "" };
    if (urlParams.has('utm_content')) { utm_content = urlParams.get('utm_content') } else { utm_content = "" };
    if (urlParams.has('utm_term')) { utm_term = urlParams.get('utm_term') } else { utm_term = "" };

    return {
      "utm_source": utm_source,
      "utm_medium": utm_medium,
      "utm_campaign": utm_campaign,
      "utm_content": utm_content,
      "utm_term": utm_term
    };
  };
  // ---------------------------------- By Mykyta

  const handleSubmit1 = (event) => {
    event.preventDefault();
    if (phone.trim() === "") {
      setPhoneError("Будь ласка, введіть номер телефону");
      return;
    }

    // остальной код отправки формы

    setPhoneError(""); // Очистить ошибку, если все в порядке
    console.log("Form submitted"); // Поставьте сюда ваш код отправки данных формы

    handleClose1(); // Закрыть модальное окно сразу после нажатия кнопки "Відправити"

    const formData = new FormData(form.current);
    const data = {
      name: formData.get("name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
    };

    emailjs
      .send("service_5gqx2v7", "template_5lesypi", data, "4upStKj7ORtxgJNce")
      .then((response) => {
        toast.success("Повідомлення успішно відправлено!");
      })
      .catch((error) => {
        toast.error("Помилка надсилання повідомлення, спробуйте ще раз.");
      });

    // -------------------------------
    const utmData = getUTMParameters();

    // Дані для відправки у форматі JSON
    const crmTgData = {
      event: event.type,
      button_id: event.target.id,
      timestamp: new Date().toISOString(),
      name: formData.get("name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
      utm_source: utmData.utm_source,
      utm_medium: utmData.utm_medium,
      utm_campaign: utmData.utm_campaign,
      utm_content: utmData.utm_content,
      utm_term: utmData.utm_term
    };

    // Відправка даних за допомогою fetch
    fetch('https://red.bidata.store/unext/landing/lead/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(crmTgData)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(crmTgData => {
        console.log('Success');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    // ------------------------------ By Mykyta
  };

  return (
    <div>
      {/* Hero section */}
      <section className="hero">
        <div className="overlay"></div>
        <Container>
          <Row className="align-items-center">
            <Col md={8} className="text-center text-md-left">
              <h1 className="mainPageText">
                Unext — твій центр <br /> підготовки до НМТ
              </h1>
              <Button
                className="cta-button"
                variant="primary"
                onClick={handleShow1}
              >
                Записатися на курс
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Modal section */}
      <RemoveScroll enabled={show1}>
        <Modal show={show1} onHide={handleClose1} backdrop="true" centered>
          <Modal.Header closeButton>
            <Modal.Title className="titleModal">
              Записатися на курс &nbsp;
              <img src={LogoItem} className="logoAfterText" alt="LogoItem" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-MainPage">
            <Form ref={form} onSubmit={handleSubmit1}>
              <Form.Group controlId="formBasicName">
                <Form.Label className="textModal">Ваше імʼя:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Будь ласка, введіть ваше ім'я"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicPhone">
                <Form.Label className="textModal">Номер телефону:</Form.Label>
                <PhoneInput
                  country={"ua"}
                  value={phone}
                  onChange={handlePhoneChange}
                  onBlur={handlePhoneBlur}
                  onFocus={handlePhoneFocus}
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: false,
                    className: "phone-input-field",
                  }}
                  containerClass="phone-input-container"
                />
                {phoneError && <div className="text-danger">{phoneError}</div>}
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="textModal">
                  Електронна адреса:
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Будь ласка, введіть електронну адресу"
                />
              </Form.Group>
              <Form.Group controlId="dataProcessingAgreement">
                <Form.Check
                  type="checkbox"
                  label="Натискаючи кнопку, я даю згоду на обробку персональних даних."
                  required
                />
              </Form.Group>
              <Modal.Footer className="custom-modal-footer">
                <Button variant="secondary" onClick={handleClose1}>
                  Закрити
                </Button>
                <Button type="submit" variant="secondary">
                  Відправити
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </RemoveScroll>

      {/* Statistics section */}
      <section className="statistics-section">
        <Container className="statistics-section">
          <Row className="justify-content-center">
            <Col md={4} className="text-center">
              <div className="statistics-item">
                <span className="statistics-number">
                  <CountUp end={180} duration={3} />
                </span>
                <span className="statistics-text">Середній бал</span>
              </div>
            </Col>
            <Col md={4} className="text-center">
              <div className="statistics-item">
                <span className="statistics-number">
                  <CountUp end={3500} duration={3} />+
                </span>
                <span className="statistics-text">Учнів підготовлено </span>
              </div>
            </Col>
            <Col md={4} className="text-center">
              <div className="statistics-item">
                <span className="statistics-number">
                  <CountUp end={99} duration={3} suffix="%" />
                </span>
                <span className="statistics-text">Задоволених клієнтів</span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default MainPage;
